<template>
  <div>
    <div class="card mb-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <p class="task-file-name">
          <span class="name">{{ fileName }}</span>
          <span class="format">.csv</span>
        </p>
        <p v-if="task.created_at" class="task-date">
          {{
            this.$dayjs(task.created_at)
              .locale("de")
              .format("DD MMM YYYY - HH:mm")
          }}
        </p>
      </div>

      <div class="card-body">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: 0%'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div v-if="task.fast_status === 'cancelled'" class="text-center">
          <h4 class="text-center">AUFTRAG ABGEBROCHEN</h4>
        </div>
        <div
          v-if="task.fast_status !== 'cancelled'"
          class="task-metrics d-flex justify-content-between"
        >
          <div class="proggress-percent">
            <div class="item">
              <p class="title text-primary">Fertig</p>
              <p class="desc text-primary">100%</p>
              <div class="action close align-items-center d-none">
                <ph-x-circle :size="18" weight="bold" />
                <p class="link">Delete</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column gap-20">
            <div class="task-metrics-items d-flex justify-content-end gap-20">
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160 
                "
              >
                <p class="title">Produkte gefunden</p>
                <p class="desc text-dark-grey">{{ metrics.totalMappingCount }}</p>
              </div>
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160 
                "
              >
                <p class="title">Auto-Mapping</p>
                <p class="desc">
                  {{
                    `${metrics.completedMatchedTypeBCount} / ${metrics.completedTypeBCount}` ||
                      "-"
                  }}
                </p>
              </div>
              <!-- <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160 
                "
              >
                <p class="title">Manuelles Mapping</p>
                <p class="desc">
                  {{
                    `${metrics.completedMatchedTypeACount} / ${metrics.completedTypeACount}` ||
                      "-"
                  }}
                </p>
              </div> -->
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160
                "
              >
                <p class="title">Mapping erfolgreich</p>
                <p class="desc text-primary">
                  {{ metrics.completedFastMappingCount }}
                </p>
              </div>
            </div>
            <p v-if="detail.current" class="text-right mt-4">
              {{ `${detail.current + 1} von ${metrics.completedTypeACount} Artikel wurden zusammengeführt` }}
            </p>
            <div class="d-flex gap-20">
              <div class="min-160"></div>
              <div class="min-160 text-center">
                <button
                  @click="goManualMap('keineTreffer')"
                  :class="metrics.completedTypeBCount === 0 ? 'btn-disable' : ''"
                  class="btn btn-white btn-sm"
                >
                  <ph-note-pencil :size="18" weight="bold" />
                  Bearbeiten
                </button>
              </div>
              <!-- <div class="min-160 text-center">
                <button
                  :class="metrics.completedTypeACount === 0 ? 'btn-disable' : ''"
                  @click="goManualMap('klarfall')"
                  class="btn btn-white btn-sm"
                >
                  <ph-note-pencil :size="18" weight="bold" />
                  Bearbeiten
                </button>
              </div> -->
              <div class="min-160 text-center">
                <button
                  class="btn btn-primary btn-sm"
                  :disabled="
                    loading.status && loading.type === 'BUTTON' ? true : false
                  "
                  @click="downloadFile(task.resource_id)"
                >
                  <ph-download-simple :size="18" weight="bold" />
                  {{
                    loading.status === true && loading.type === "BUTTON"
                      ? "Lädt.."
                      : "Exportieren"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API_ROUTES from "@/utils/API_ROUTES.js";
export default {
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      task: null,
      loading: {
        status: true,
        type: null
      }
    };
  },
  computed: {
    fileName() {
      const name = this.detail.resource_name;
      return name.split(".csv")[0];
    },
    metrics() {
      if (this.task.metrics) {
        return JSON.parse(this.task.metrics, true);
      }
      return {};
    }
  },
  created() {
    this.task = this.detail;
    this.getTaskDetail();
  },
  methods: {
    async getTaskDetail() {
      let response = await this.$axios.get(
        API_ROUTES.getTaskResultSlow(this.detail.resource_id)
      );
      this.task = response.data;
    },
    goManualMap(type) {
      if (type === "keineTreffer") {
        return this.$router.push(
          `/processing/auto-mapping?id=${
            this.task.resource_id
          }&type=${"klarfall"}`
        );
      }
      // return this.$router.push(
      //     `/processing/empty-mapping?id=${
      //       this.task.resource_id
      //     }&type=${"klarfall"}`
      //   );
    },
    async downloadFile(id) {
      try {
        this.loading.status = true;
        this.loading.type = "BUTTON";
        let result = await this.$axios.get(API_ROUTES.getTaskResult(id));
        window.location = result.data.csv_url;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading.status = false;
        this.loading.type = null;
      }
    }
  }
};
</script>
