<template>
  <div class="d-flex justify-content-start">
    <Sidebar />
    <div
      @scroll="onScroll"
      class="content overflow-auto content d-flex flex-column vh-100"
    >
      <div class="d-flex justify-content-between py-5 px-4">
        <h5>Ergebnisse</h5>
        <div class="summary">
          <span class="bold">{{ pagination.totalCount }} CSV</span>
          <span>Dateien</span>
        </div>
      </div>
      <div v-if="loading" class="loading-wrapper">
        <div class="loader"></div>
      </div>
      <div
        v-if="!loading && historyList.length === 0"
        class="d-flex flex-grow-1 h-100 align-items-center justify-content-center"
      >
        <h3>Keine fertig bearbeiteten Aufträge vorhanden</h3>
      </div>
      <div v-if="historyList.length > 0" class="cards">
        <template v-for="task in historyList">
          <history-item :detail="task" :key="task.resource_id" />
        </template>
        <div v-if="loadingMore" class="loadmore-loading">
          <div class="loader loader-small"></div>
        </div>
        <div v-if="notMoreData" class="load-more-loading">
          <h4 class="text-center mb-4">KEINE WEITEREN AUFTRÄGE</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API_ROUTES from "@/utils/API_ROUTES.js";
import HistoryItem from "../components/HistoryItem.vue";
export default {
  name: "Historty",
  components: {
    HistoryItem
  },
  data() {
    return {
      loading: true,
      notMoreData: false,
      width: 62,
      currentPage: 0,
      historyList: [],
      pagination: {},
      loadingMore: false
    };
  },

  computed: {
    fullWidth() {
      return `width:${this.width}%`;
    }
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getList();
      }
    },
    async getList() {
      try {
        if (this.historyList.length === this.pagination.totalCount) {
          return (this.notMoreData = true);
        }
        if (
          this.currentPage ===
          Math.ceil(this.pagination.totalCount / this.pagination.limit)
        ) {
          return (this.notMoreData = true);
        }
        this.currentPage++;
        if (this.currentPage !== 1) {
          this.loadingMore = true;
        }
        this.loading = true;
        let response = await this.$axios.get(
          API_ROUTES.getHistoryList("completed,cancelled,error", this.currentPage)
        );
        response.data.tasks.items.map(async item => {
          if (item.slow_status !== "pending") {
            const res = await this.$axios.get(API_ROUTES.getProgress(item.resource_id))
            item.current = res.data[0] ? res.data[0].payload.current : 0
            this.historyList.push(item);
          }
        });
        this.pagination = response.data.tasks.paginator;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    }
  },
  created() {
    this.getList();
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 40px;
}
.summary {
  span {
    font-size: 14px;
    margin-left: 2px;
    color: #909090;
  }
}
</style>
